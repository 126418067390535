import React from "react"
import tw from 'twin.macro'
import * as moment from 'moment'

require('@gouch/to-title-case')

function ChangelogItem({ title, type, date, children }) {
  const adjustedDate = moment(date).subtract(1, 'hours')
  const relativeDate = moment.utc(adjustedDate).fromNow()

  const typeColor =
    type === 'fix'
      ? tw`bg-status-fix dark:(bg-status-dark-fix)`
      : type === 'new'
      ? tw`bg-status-new dark:(bg-status-dark-new)`
      : type === 'update'
      ? tw`bg-status-update dark:(bg-status-dark-update)`
      : type === 'enhancement'
      ? tw`bg-status-enhancement dark:(bg-status-dark-enhancement)`
      : type === 'addition'
      ? tw`bg-status-addition dark:(bg-status-dark-addition)`
      : tw`bg-status-fallback dark:(bg-status-dark-fallback)`

  return (
    <div tw="grid max-w-2xl py-5 mx-auto grid-template-columns[1fr] md:(py-8 grid-template-columns[minmax(100px, 1.3fr) 3fr]) lg:(py-12 grid-template-columns[minmax(100px, 1.3fr) 3fr])">
      <div>
        <p tw="text-xs text-thirdgray-500 py-2 md:(text-right pr-8) dark:(text-thirdgray-dark-500)">{relativeDate}</p>
      </div>
      <div>
        <h3 tw="font-bold text-lg mb-1 lg:(text-xl)">
          <div>{title.toTitleCase()}</div>
        </h3>
        <div
          css={[
            tw`inline-block my-2 rounded-full bg-thirdgray-900 text-white text-sm px-3 py-0.5 font-bold mr-2`,
            typeColor
          ]}>
          {type.toTitleCase()}
        </div>
        <div>
          <p tw="text-thirdgray-900 lg:(text-lg) dark:(text-thirdgray-dark-900)">
            {children}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ChangelogItem
