import React from "react"
import 'twin.macro'
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Footer from "../components/footer"

import ChangelogItem from "../components/changelog/item"

const ChangelogPage = ({ data }) => (
  <Layout styleContext="page">
    <Seo title="Changelog"/>
    <Section>
      <div tw="pt-8 px-3 md:(pt-3) divide-y divide-thirdgray-200 dark:(divide-thirdgray-dark-200)">
        <ChangelogItem
          title='Perth’s Best Brunch'
          date='2021-06-17 18:44'
          type='new'>
          Added <Link to="/collections/perths-best-brunch/">Perth’s Best Brunch</Link> article.
        </ChangelogItem>
        <ChangelogItem
          title='Infrastructure updates'
          date='2021-04-06 23:30'
          type='enhancement'>
          We’ve upgraded to Gatsby 3, and migrated from Theme UI to Tailwind CSS. The ideal outcome from these changes will be minimally noticeable.<br/><br/>We also took the opportunity to update the design of the Home page and Collections page.
        </ChangelogItem>
        <ChangelogItem
          title='Ryan and Seren Chu Talk Days Off'
          date='2021-04-02 12:00'
          type='new'>
          Added <Link to="/collections/ryan-and-seren-chu-talk-days-off/">Ryan and Seren Chu Talk Days Off</Link> article.
        </ChangelogItem>
        <ChangelogItem
          title='Update Where to Go for the Best Coffee in Perth'
          date='2021-02-19 18:00'
          type='update'>
          <Link to="/collections/where-to-go-for-the-best-coffee-in-perth/">Where to Go for the Best Coffee in Perth</Link> has been updated, replacing <Link to="/places/arlo/">Arlo</Link> with <Link to="/places/hemingway/">Hemingway</Link>, and included <Link to="/places/modus/">Modus</Link>’ East Victoria Park location.
        </ChangelogItem>
        <ChangelogItem
          title='Add Cozy Del’s to the Directory'
          date='2021-02-12 18:00'
          type='addition'>
          <Link to="/places/cosy-dels/">Cosy Del’s</Link> has been added to the Directory.
        </ChangelogItem>
        <ChangelogItem
          title='Where to Find Perth’s Best Pizza'
          date='2021-02-12 08:00'
          type='new'>
          Added <Link to="/collections/perths-best-pizza/">Where to Find Perth’s Best Pizza</Link> article.
        </ChangelogItem>
        <ChangelogItem
          title='Add North Street Store to the Directory'
          date='2021-02-05 17:00'
          type='addition'>
          <Link to="/places/north-street-store/">North Street Store</Link> has been added to the Directory.
        </ChangelogItem>
        <ChangelogItem
          title='Where to Find Perth’s Best Bread'
          date='2021-02-05 17:00'
          type='new'>
          Added <Link to="/collections/perths-best-bread/">Where to Find Perth’s Best Bread</Link> article.
        </ChangelogItem>
        <ChangelogItem
          title='Public Holiday filtering'
          date='2021-01-25 12:54'
          type='enhancement'>
          Added temporary public holiday filtering for January 26th to the directory.
        </ChangelogItem>
        <ChangelogItem
          title='Monsterella’s Tania Niccolo on Her Family’s Weekend Hangs'
          date='2021-01-22 12:00'
          type='new'>
          <Link to="/collections/monsterellas-tania-niccolo-on-her-familys-weekend-hangs/">Published Monsterella’s Tania Niccolo on Her Family’s Weekend Hangs</Link>
        </ChangelogItem>
        <ChangelogItem
          title='Add Hemingway and Rossonero to the Directory'
          date='2020-09-02 09:00'
          type='addition'>
          <Link to="/places/hemingway/">Hemingway</Link> and <Link to="/places/rossonero/">Rossonero</Link> have been added to the Directory.
        </ChangelogItem>
        <ChangelogItem
          title='Updating Places, Handling Closures'
          date='2020-08-12 10:00'
          type='update'>
          We’re in the process of going through, and updating opening hours and addresses for locations.<br/><br/>
          Businesses that are permanently closed will be omitted from the directory, and their listing will be updated to reflect the closure.
        </ChangelogItem>
        <ChangelogItem
          title='Resuming ThirdPlaces'
          date='2020-08-09 16:00'
          type='new'>
          It’s been almost six months since our last update, and a lot has happened—here in Perth and around the world.<br/><br/>
          We’re tentatively resuming work on ThirdPlaces.
        </ChangelogItem>
        <ChangelogItem
          title='Public holiday filtering'
          date='2020-02-27 20:00'
          type='enhancement'>
          We’ve added contextual public holiday filtering and hours to our directory, in prepartion for Labour Day (Monday,&nbsp;March 20).<br/><br/>
          These filters will appear contextually throughout the year in the lead up to Public Holidays.
        </ChangelogItem>
        <ChangelogItem
          title='Add Canteen Pizza, Mo Allendale, Yuuma, and Swallow to the directory'
          date='2020-02-27 19:30'
          type='addition'>
          <Link to="/places/canteen-pizza">Canteen Pizza</Link>, <Link to="/places/mo-allendale/">Mo Allendale</Link>, <Link to="/places/yuuma/">Yuuma</Link>, and <Link to="/places/swallow">Swallow</Link> have been added to the Directory.
        </ChangelogItem>
        <ChangelogItem
          title='Update hours for Helvetica and Foxtrot Unicorn'
          date='2020-02-20 16:56'
          type='update'>
          The opening hours for <Link to="/places/foxtrot-unicorn">Foxtrot Unicorn</Link>, <Link to="/places/helvetica">Helvetica</Link> have changed, and we’ve updated them accordingly.
        </ChangelogItem>
        <ChangelogItem
          title='Update footer'
          date='2020-02-14 21:41'
          type='enhancement'>
          Update design of the footer, add link to the Changelog, and add social media links.
        </ChangelogItem>
        <ChangelogItem
          title='Add Strange Company to Perth’s Best Wine'
          date='2020-02-13 18:56'
          type='update'>
          <Link to="/places/strange-company">Strange Company</Link> has been added to the <Link to="/collections/perths-best-wine">Perth’s Best Wine</Link> collection.
        </ChangelogItem> 
        <ChangelogItem
          title='Add Helvetica, Strange Company, and The Flour Factory to the directory'
          date='2020-02-13 18:35'
          type='addition'>
          Added <Link to="/places/helvetica">Helvetica</Link>, <Link to="/places/strange-company">Strange Company</Link>, and <Link to="/places/flour-factory">The Flour Factory</Link> to the directory.
        </ChangelogItem>
         <ChangelogItem
          title='Fix the display of footer on mobile'
          date='2020-02-12 21:00'
          type='fix'>
          Fixes the display of the footer on Mobile (for viewports of at least 375pt wide).
        </ChangelogItem>
        <ChangelogItem
          title='Add Habitué to the directory'
          date='2020-02-12 19:00'
          type='addition'>
          <Link to='/places/habitue'>Habitué</Link> has been added to the directory.
        </ChangelogItem>
        <ChangelogItem
          title='Introducing our Changelog'
          date='2020-02-12 07:30'
          type='new'>
          Introducing our new Changelog feature, which will keep a running list of changes to the website, for anyone curious.
        </ChangelogItem>
        <ChangelogItem
          title='Fix title case in collection banners'
          date='2020-02-12 08:30'
          type='fix'>
          Title Casing is now enforced in Collection titles throughout the website.
        </ChangelogItem>
        <ChangelogItem
          title='Consistent display of opening hours'
          date='2020-02-11 23:50'
          type='fix'>
          We identified a bug which caused the display of opening hours to display incorrectly for some browser clients, depending on timezone.
          <br/><br/>
          Thanks to Jared for the heads-up!
        </ChangelogItem>
        <ChangelogItem
          title='Collection transitions'
          date='2020-02-11 19:51'
          type='enhancement'>
          We have added some subtle page transitions to collection article banners.
        </ChangelogItem>
      </div>
    </Section>
    <Footer/>
  </Layout> 
)

export default ChangelogPage
